import Head from "next/head";
import type { ReactNode } from "react";

const ErrorLayout = ({
  children,
  title = "PrivateKeys.in - Crypto Keys Database",
}: {
  children: ReactNode;
  title?: string;
}) => {
  return (
    <>
      <Head>
        <title>{title}</title>
      </Head>
      <div className="min-h-screen bg-gray-800 text-gray-100 p-10">
        {children}
      </div>
    </>
  );
};

export default ErrorLayout;
